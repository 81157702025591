const theme = {
  global: {
    font: {
      family: "Quicksand",
      size: "14px",
      height: "20px",
    },
  },
};

export default theme;
